import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import VendCadCli from '../vendClient/vendClient';
import Logo from '../../img/logoMenor.png';
import { FaSpinner } from 'react-icons/fa';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(''); // Defina a variável de estado para a mensagem
    const [loading, setLoading] = useState(false); // Defina a variável de estado para o estado de carregamento
    const [loggedIn, setLoggedIn] = useState(false); // Defina a variável de estado para o status de login

    const handleLogin = async () => {
        setLoading(true); // Define loading como verdadeiro durante o processo de login
        try {
            const response = await axios.post('/login', { username, password });
            setMessage(response.data.message);
            setLoggedIn(true); // Define loggedIn como verdadeiro após um login bem-sucedido
        } catch (error) {
            setMessage('Credenciais inválidas');
        }
        setLoading(false); // Define loading como falso após o processo de login, independentemente do resultado
    };

    return (
        <div className="login-container">

            <br />
            {!loggedIn ? (
                <div className="login-form">
                    <img className="img" src={Logo} alt="Logo" />
                    <input
                        className="input-form"
                        type="text"
                        placeholder="Usuário"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        className="input-form"
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className="login-button" onClick={handleLogin} disabled={loading}>
                        {loading ? <FaSpinner className="spinner-icon" /> : 'Login'}
                    </button>
                    <p>{message}</p> {/* Mostra a mensagem de erro ou sucesso */}
                </div>
            ) : (
                <div className="">
                    {loggedIn && <VendCadCli />}

                </div>
            )}
        </div>
    );
}

export default Login;
