import React, { useState } from 'react';
import axios from 'axios';
import './Log.css';

const Alert = ({ message, onClose }) => {
    return (
        <div className="alert">
            <span className="closeBtn" onClick={onClose}>&times;</span>
            {message}
        </div>
    );
};

const estadosBrasil = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
];

const CadastroCliente = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [cliente, setCliente] = useState({
        nome: '',
        icms: false,
        isencaoICMS: false,
        numeroICMS: '',
        telefone: '',
        email: '',
        inscricaoEstadual: '',
        nomeEmpresa: '',
        estado: ''
    });

    // Movido para fora da função handleSubmit
    const textStyle = {
        backgroundColor: '#3498db', // Substitua pela cor desejada
        color: '#fff', // Cor do texto
        padding: '10px', // Espaçamento interno
        borderRadius: '5px'
    };

    const handleShowAlert = () => {
        setShowAlert(true);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        setCliente((prevCliente) => {
            if (name === 'isencaoICMS') {
                return {
                    ...prevCliente,
                    [name]: checked,
                    inscricaoEstadual: checked ? '' : prevCliente.inscricaoEstadual,
                };
            } else {
                return {
                    ...prevCliente,
                    [name]: type === 'checkbox' ? checked : value,
                };
            }
        });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Use prevCliente para garantir que você está verificando o estado mais recente
        console.log('cliente.icms:', cliente.icms);
        console.log('cliente.inscricaoEstadual:', cliente.inscricaoEstadual);
    
    
        axios.post('/cadClient', cliente)
            .then(response => {
                console.log(response.data);
                handleShowAlert();  // Mostra o alerta de sucesso
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <div className="cadastro-cliente" >

            <form onSubmit={handleSubmit}>
                <input
                    placeholder="Nome completo"
                    type="text"
                    name="nome"
                    value={cliente.nome}
                    onChange={handleChange}
                    required
                />
                <br />
                <p>Contribuinte do ICMS:</p>
                <div className="btnContainer">
                    <button
                        className={`btn ${cliente.icms ? 'selected' : ''}`}
                        onClick={() => setCliente({ ...cliente, icms: true })}
                    >
                        Sim
                    </button>
                    <button
                        className={`btn ${!cliente.icms ? 'selected' : ''}`}
                        onClick={() => setCliente({ ...cliente, icms: false })}
                    >
                        Não
                    </button>
                </div>
                <br />
                {cliente.icms && (
                    <React.Fragment>
                        <label>
                            <input
                                type="checkbox"
                                name="isencaoICMS"
                                checked={cliente.isencaoICMS}
                                onChange={handleChange}
                            />
                            Isenção de IE
                        </label>
                        <br />
                        <input
                            placeholder={cliente.isencaoICMS ? 'Inscrição estadual' : 'Inscrição estadual (obrigatório)'}
                            type="tel"
                            name="inscricaoEstadual"
                            value={cliente.inscricaoEstadual}
                            onChange={handleChange}
                            required={!cliente.isencaoICMS}
                        />
                        <br />
                    </React.Fragment>
                )}
                <input
                    placeholder="Telefone"
                    type="tel"
                    name="telefone"
                    value={cliente.telefone}
                    onChange={handleChange}
                    required
                />
                <br />
                <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={cliente.email}
                    onChange={handleChange}
                    required
                />
                <br />
                <input
                    placeholder="Razão social"
                    type="text"
                    name="nomeEmpresa"
                    value={cliente.nomeEmpresa}
                    onChange={handleChange}
                    required
                />
                <br />
                <label>
                    Estado:
                    <select name="estado" value={cliente.estado} onChange={handleChange}>
                        <option value="">Selecione um estado</option>
                        {estadosBrasil.map((estado, index) => (
                            <option key={index} value={estado}>
                                {estado}
                            </option>
                        ))}
                    </select>
                </label>
                <br />
                <div style={textStyle}>
                    <button className="btn" onClick={handleShowAlert}>Cadastrar</button>

                    <strong>{showAlert && (
                        <Alert message="Cadastro feito com sucesso!" onClose={handleCloseAlert} />
                    )}</strong>
                </div>
            </form>

        </div>
    );
};

export default CadastroCliente;
