// logClient.js
import React, { useState } from 'react';
import axios from 'axios';
import './logClient.css';
import { useNavigate, useParams } from 'react-router-dom';

function Log({ onLoginSuccess }) {
    const [cnpjCpf, setCnpjCpf] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { id } = useParams(); // Move para o escopo do componente

    const handleChange = (e) => {
        setCnpjCpf(e.target.value);
        setError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`/logClient/${id}`, { cnpjCpf })
            .then(response => {
                console.log(response.data);
                onLoginSuccess(); // Chama a função para indicar sucesso no login
                navigate('/cadastro'); // Redireciona para a página de cadastro
            })
            .catch(error => {
                console.error(error);
                setError('Cliente não encontrado. Verifique o CPF/CNPJ e tente novamente.');
            });
    };

    return (
        <div className="container-login">
            <div className="formulario-cliente">
                <h2>Área de Login</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        CPF ou CNPJ:
                        <input type="text" maxLength="14" name="cpfCnpj" value={cnpjCpf} onChange={handleChange} required />
                    </label> <br />
                    <button type="submit">Enviar</button>
                    {error && <div className="mensagem-erro">{error}</div>}
                </form>
            </div>
        </div>
    );
}

export default Log;
