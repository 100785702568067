import React, { useState } from 'react';
import axios from 'axios';

function VendCli() {
    const [cnpjCpf, setCnpjCpf] = useState('');
    const [error, setError] = useState('');
    const [link, setLink] = useState(null);

    const handleChange = async (e) => {
        setCnpjCpf(e.target.value);
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/cnpjCad', { cnpjCpf });
            console.log(response.data.message);

            // Atualiza o estado do link com o retorno da rota
            setLink(response.data.link);
            setError('');
            alert('Cliente cadastrado com sucesso'); // Adicionando o alerta aqui
        } catch (error) {
            console.error(error.response.data.error);
            setError(error.response.data.error);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <label>
                    CNPJ/CPF:
                    <input className="input-form" type="text" maxLength="14" name="cnpjCpf" value={cnpjCpf} onChange={handleChange} required />
                </label> <br />
                <button className="login-button" type="submit">Cadastrar</button>
                {link && (
                    <div className='link'>
                        <br />
                        <h3>LINK PARA CLIENTE:</h3>
                        <p>{link}</p>
                    </div>
                )}
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}

        </div>
    );
}

export default VendCli;