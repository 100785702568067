import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LogClient from './components/logClient/logClient';
import CadClient from './components/cadClient/cadClient';
import LoginVend from './components/Login/Login';
import Cadcnpj from './components/vendClient/vendClient';


function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLoginSuccess = () => {
    setLoggedIn(true);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login/:id" element={<LogClient onLoginSuccess={handleLoginSuccess} loggedIn={loggedIn} />} />

        <Route path="/cadastro" element={loggedIn ? <CadClient /> : <Navigate to="/login/acesso" />} />
      
        <Route path="/login-vend" element={<LoginVend onLoginSuccess={handleLoginSuccess} loggedIn={loggedIn}/>} />
      
        <Route path="/cad-cnpj" element={loggedIn ? <Cadcnpj /> : <Navigate to="/login-vend" />} />
      </Routes>
    </Router>
  );
}

export default App;